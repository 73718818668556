<template>
    <div class="registration-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('incubator.registration.title[0]') }}
        </div>
        <Anchor :id="'qualification'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[1]') }}
            </div>

            <div class="block-content">
                <content-renderer :content="content.qualifications" />
            </div>
        </div>
        <Anchor :id="'apply-method'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[2]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.applyMethod" />
            </div>
        </div>
        <Anchor :id="'judge-method'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[3]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.judgeMethod" />
            </div>
        </div>
        <Anchor :id="'schedule'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[4]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.schedule" />
            </div>
        </div>
        <Anchor :id="'grants'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[5]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.grants" />
            </div>
        </div>
        <Anchor :id="'theme'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[6]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.theme" />
            </div>
        </div>
        <Anchor :id="'contact-us'" />
        <div class="sub-block contact">
            <div class="sub-title">
                {{ $t('incubator.registration.title[7]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.contact" />
            </div>
        </div>
        <Anchor :id="'rights'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[8]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.rights" />
            </div>
        </div>
    </div>
</template>

<script>
import ContentRenderer from '../ContentRenderer.vue';

export default {
    components: {
        ContentRenderer,
    },
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss">
.registration-block {
  .apply-methods-img{
    width: 100%;
    margin-top: 10px;
  }
}
</style>
